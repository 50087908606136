// src/components/MobileSidebar/index.tsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Stage, Year, Resource } from '../../App';
import './styles.scss';
import Modal from '../Modal';
import Logo from '../../assets/mobile/logo.svg';

interface MobileSidebarProps {
  stages: Stage[];
  onYearSelect: (yearName: string) => void;
}

const MobileSidebar: React.FC<MobileSidebarProps> = ({ stages, onYearSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState<Year | null>(null);
  const [selectedYearImage, setSelectedYearImage] = useState<string>('');
  const [modalUrl, setModalUrl] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const menuIconRef = useRef<HTMLDivElement>(null);

  const toggleSidebar = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const handleYearSelect = useCallback((year: Year) => {
    setSelectedYear(year);
    setSelectedYearImage(year.titleYear);
    onYearSelect(year.name);
  }, [onYearSelect]);

  const handleOpenModal = (url: string, title: string) => {
    setModalUrl(url);
    setModalTitle(title);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const initialStage = stages.find(s => s.name === "Fundamental I");
    const initialYear = initialStage?.years.find(y => y.name === "1º ano");
    if (initialStage && initialYear && !selectedYear) {
      handleYearSelect(initialYear);
    }
  }, [stages, selectedYear, handleYearSelect]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node) &&
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const getMenuImage = (stageName: string) => {
    if (stageName === "Fundamental I") {
      return "./assets/mobile/menu-ef1.svg";
    } else if (stageName === "Fundamental II") {
      return "./assets/mobile/menu-ef2.svg";
    } else if (stageName === "Ensino Médio") {
      return "./assets/mobile/menu-em.svg";
    }
    return "";
  };

  const getTopbarClass = (yearName: string | null): string => {
    if (['1º ano', '2º ano', '3º ano', '4º ano', '5º ano'].includes(yearName || '')) {
      return 'topbar1';
    } else if (['6º ano', '7º ano', '8º ano', '9º ano'].includes(yearName || '')) {
      return 'topbar2';
    } else if (['1ª serie', '2ª serie', '3ª serie'].includes(yearName || '')) {
      return 'topbar3';
    } else {
      return 'topbar'; // Classe padrão
    }
  };

  return (
    <>
      <div className={getTopbarClass(selectedYear ? selectedYear.name : null)}>
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="hamburger-menu" onClick={toggleSidebar} ref={menuIconRef}>
          <img src="./assets/mobile/mc.svg" alt="Menu" />
        </div>
      </div>
      <div ref={sidebarRef} className={`mobile-sidebar ${isOpen ? 'open' : ''}`}>
        {isOpen && stages.map((stage, index) => (
          <div key={index} className="menu-section">
            {stage.years.map((year, yearIndex) => (
              <div
                key={yearIndex}
                className={`year-entry ${selectedYear === year ? 'selected' : ''}`}
                onClick={() => handleYearSelect(year)}
              >
                <img src={`./assets/mobile/${year.name.replace('º ano', 'ano').replace('ª serie', 'serie')}.svg`} alt={year.name} />
              </div>
              ))}
              <img src={getMenuImage(stage.name)} alt="Menu Título" className="menu-title" />
          </div>
        ))}
      </div>
      {selectedYear && (
        <div className="page-title">
          <img src={selectedYearImage} alt={`Título do ${selectedYear.name}`} />
        </div>
      )}
      {selectedYear && (
        <div className="thumbnails">
          {selectedYear.resources.map((resource: Resource, index) => (
            <img
              key={index}
              src={resource.thumb}
              alt="Thumbnail"
              onClick={() => handleOpenModal(resource.url, resource.title)}
              className={resource.comingSoon ? 'thumbEmBreve' : ''}
            />
          ))}
        </div>
      )}
      <Modal url={modalUrl} title={modalTitle} isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </>
  );
};

export default MobileSidebar;
