// Code: Sidebar component
import { Stage, Year, Resource } from "../../App";
import { Fragment, useEffect, useState } from "react";
import "./styles.scss";
import Modal from "../Modal";
import Thumbs from "../Thumbs";

interface SidebarProps {
  stages: Stage[];
  onYearSelect: (yearName: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ stages, onYearSelect }) => {
  const [hovered, setHovered] = useState<Year | null>(null);
  const [selectedYear, setSelectedYear] = useState<Year | null>(null);
  const [selectedYearImage, setSelectedYearImage] = useState<string>('');
  const [modalUrl, setModalUrl] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const initialStage = stages.find(s => s.name === "Fundamental I");
    const initialYear = initialStage?.years.find(y => y.name === "1º ano");
    if (initialStage && initialYear && !selectedYear) {
      setSelectedYear(initialYear);
      setSelectedYearImage(initialYear.titleYear);
      onYearSelect(initialYear.name);
    }
  }, [stages, selectedYear, onYearSelect]);

  const handleOpenModal = (url: string, title: string) => {
    setModalUrl(url);
    setModalTitle(title);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="sidebar">
        {stages.map((stage, index1) => (
          <Fragment key={index1}>
            {stage.years.map((year, index2) => {
              const isSelected = selectedYear === year;
              const info = isSelected ? year.book.hover : year.book.normal;
              return (
                <div
                  className={`book book${index1}${index2} ${isSelected ? 'book-selected' : ''}`}
                  key={`${index1}${index2}`}
                  onMouseEnter={() => setHovered(year)}
                  onMouseLeave={() => setHovered(null)}
                  onClick={() => {
                    setSelectedYear(year);
                    setSelectedYearImage(year.titleYear);
                    onYearSelect(year.name);
                  }}
                  style={{ position: "absolute", top: info.y, left: info.x }}
                >
                  <img src={info.image} alt={`Capa do livro do ${year.name}`} />
                </div>
              );
            })}
          </Fragment>
        ))}
        {selectedYear && (
          <>
            <div className="page-title" >
              <img src={selectedYearImage} alt={`Título do ${selectedYear.name}`} />
            </div>
            <Thumbs resources={selectedYear.resources} onOpenModal={handleOpenModal} />
            <Modal url={modalUrl} title={modalTitle} isOpen={isModalOpen} onRequestClose={handleCloseModal} />
          </>
        )}
      </div>
    </>
  );
};

export default Sidebar;
