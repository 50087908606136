import React, { useEffect, useState } from 'react';
import './styles.scss';

interface ThumbsProps {
  resources: { thumb: string, url: string, title: string, comingSoon?: boolean }[];
  onOpenModal: (url: string, title: string) => void;
}

const Thumbs: React.FC<ThumbsProps> = ({ resources, onOpenModal }) => {
  const [selectedThumb, setSelectedThumb] = useState<number | null>(null);

  useEffect(() => {
    const thumbs = document.querySelectorAll('.thumb') as NodeListOf<HTMLElement>;
    thumbs.forEach((thumb, index) => {
      thumb.addEventListener('click', () => {
        const link = thumb.getAttribute('data-link') || '';
        const title = thumb.getAttribute('data-titulo') || '';
        onOpenModal(link, title);

        setSelectedThumb(index === selectedThumb ? null : index);
      });

      thumb.addEventListener('mouseover', () => {
        thumb.style.zIndex = '999';
      });

      thumb.addEventListener('mouseout', () => {
        thumb.style.zIndex = index === selectedThumb ? '2' : '1';
      });
    });
  }, [resources, onOpenModal, selectedThumb]);

  return (
    <div className="thumbnails">
      {resources.map((resource, index) => (
        <img
          key={index}
          src={resource.thumb}
          alt="Thumbnail"
          className={`thumb ${index === selectedThumb ? 'selected' : ''} ${resource.comingSoon ? 'thumbEmBreve' : ''}`}
          data-link={resource.url}
          data-titulo={resource.title}
          style={{ zIndex: index === selectedThumb ? '2' : '1' }}
        />
      ))}
    </div>
  );
};

export default Thumbs;
