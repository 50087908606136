// components/Modal/index.tsx

import React from 'react';
import ReactModal from 'react-modal';
import './style.scss';

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  url: string;
  title?: string;
}

const CustomModal: React.FC<ModalProps> = ({ isOpen, onRequestClose, url, title }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={title}
      ariaHideApp={false}
      className="modalContent"
      overlayClassName="modalOverlay"
    >
      <div className="modalContainer">
        <div className="modalHeader">
          <span className="closeButton" onClick={onRequestClose}>
            X
          </span>
          <div className="emptyTitle" />
        </div>
        <iframe className="modalIframe" src={url} title={title} frameBorder="0" allowFullScreen></iframe>
      </div>
    </ReactModal>
  );
};

export default CustomModal;
