//Code: App 
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./fonts.css";
import Sidebar from "./components/Sidebar";
import MobileSidebar from "./components/MobileSidebar";
import stages from './data/stages.json';
import useWindowSize from './hooks/useWindowSize'; // Supondo que você tenha um hook para verificar o tamanho da janela

export type Stage = {
  name: string;
  x: string;
  y: string;
  years: Year[];
};

export type Year = {
  name: string;
  titleYear: string;
  book: {
    normal: {
      image: string;
      x: string;
      y: string;
    };
    hover: {
      image: string;
      x: string;
      y: string;
    };
  };
  resources: Resource[];
};

export type Resource = {
  thumb: string;
  url: string;
  title: string;
  comingSoon: boolean;
};

function App() {
  const [zoom, setZoom] = useState(1);
  const [selectedYear, setSelectedYear] = useState('1º ano');
  const size = useWindowSize(); // Hook para verificar o tamanho da janela

  // Atualize a lógica para considerar largura e altura
  const isMobile = size?.width && size.width <= 768 || size?.height && size.height <= 450;

  const updateBodyBackgroundColor = (year: string) => {
    if (['1º ano', '2º ano', '3º ano', '4º ano', '5º ano'].includes(year)) {
      document.body.style.backgroundColor = "rgb(161, 132, 216)";
    } else if (['6º ano', '7º ano', '8º ano', '9º ano'].includes(year)) {
      document.body.style.backgroundColor = "rgb(94, 202, 203)";
    } else if (['1ª serie', '2ª serie', '3ª serie'].includes(year)) {
      document.body.style.backgroundColor = "rgb(245, 173, 94)";
    } else {
      document.body.style.backgroundColor = ""; // Cor padrão
    }
  };

  // Função para pré-carregar imagens
  const preloadImages = (srcArray: string[]) => {
    srcArray.forEach((src: string) => {
      const newImage = new Image();
      newImage.src = src;
    });
  };

  useEffect(() => {
    const imageSources = stages.flatMap(stage =>
      stage.years.flatMap(year => [
        year.book.normal.image,
        year.book.hover.image,
        ...year.resources.map(resource => resource.thumb)
      ])
    );

    preloadImages(imageSources);

    if (!isMobile) {
      const handleResize = () => {
        setZoom(Math.min(window.innerWidth / 1920, window.innerHeight / 1080));
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    } else {
      setZoom(1); // Reset zoom for mobile
    }
  }, [isMobile]);

  useEffect(() => {
    updateBodyBackgroundColor(selectedYear);
  }, [selectedYear]);

  const getBackgroundClass = () => {
    if (['1º ano', '2º ano', '3º ano', '4º ano', '5º ano'].includes(selectedYear)) {
      return "background1";
    } else if (['6º ano', '7º ano', '8º ano', '9º ano'].includes(selectedYear)) {
      return "background2";
    } else if (['1ª serie', '2ª serie', '3ª serie'].includes(selectedYear)) {
      return "background3";
    }
    return "background1";
  };

  return (
    <Router>
      <div className={`app ${getBackgroundClass()}`} style={{ zoom: zoom, width: isMobile ? '100vw' : '1920px', height: isMobile ? '100vh' : '1080px' }}>
        {isMobile ? (
          <MobileSidebar stages={stages} onYearSelect={setSelectedYear} />
        ) : (
          <Sidebar stages={stages} onYearSelect={setSelectedYear} />
        )}
        <div className="content">
          <Routes>
            <Route path="/" element={<></>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;